<template>
  <div id="app">
    <header>
      <h1>公司名称</h1>
      <nav>
        <ul>
          <li><a href="#about">关于我们</a></li>
          <li><a href="#services">服务</a></li>
          <li><a href="#contact">联系我们</a></li>
        </ul>
      </nav>
    </header>
    <main>
      <section id="about">
        <h2>关于我们</h2>
        <p>公司简介内容...</p>
      </section>
      <section id="services">
        <h2>我们的服务</h2>
        <ul>
          <li>服务1</li>
          <li>服务2</li>
          <li>服务3</li>
        </ul>
      </section>
      <section id="contact">
        <h2>联系我们</h2>
        <p>邮箱: contact@company.com</p>
        <p>电话: 123-456-7890</p>
      </section>
    </main>
    <footer>
      <p>&copy; 2023 公司名称. 保留所有权利.</p>
    </footer>
  </div>
</template>

<script>
export default {
  name: 'App',
};
</script>

<style>
/* 在这里添加你的样式 */
body {
  font-family: Arial, sans-serif;
}

header {
  background-color: #4CAF50;
  color: white;
  padding: 15px 10px;
  text-align: center;
}

nav ul {
  padding: 0;
  list-style-type: none;
}

nav ul li {
  display: inline;
  margin: 0 10px;
}

main {
  padding: 20px;
}

</style>